.competitives-advantages {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: get-color(light);
    padding: 4rem 2rem;
    @include media(get-bp(tablet-portrait)) {
        padding: 8rem 2rem;
    }
    @include media(get-bp(desktop-big)) {
        overflow: inherit;
    }
    .section-title {
        margin-bottom: 4rem;
        position: relative;
        z-index: 2;
    }
}

.competitives-advantages__list {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 175rem;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media(get-bp(tablet-landscape)) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include media(get-bp(desktop)) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.competitives-advantages__list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 3rem;
    color: get-color(light);
    &:nth-child(odd) {
        background: get-color(secondary);
    }
    &:nth-child(even) {
        background: get-color(primary);
    }
    @include media(get-bp(tablet-landscape)) {
        padding: 8rem 3rem;
    }
    @include media(get-bp(desktop)) {
        padding: 5rem 3rem;
    }
    .fa-solid {
        font-size: 4rem;
        margin-bottom: 1.2rem;
    }
    p {
        text-align: center;
    }
}

.competitives-advantages__markwater {
    display: none;
    position: absolute;
    z-index: 1;
    top: -12.9rem;
    right: -5.8rem;
    padding: 5rem 3rem;
    @include media(get-bp(desktop)) {
        display: block;
    }
    @include media(get-bp(desktop-big)) {
        right: 25rem;
    }
}
