// Circular STD
@include import-font-face('Circular STD', '../../fonts/circular-std/300', 300);
@include import-font-face('Circular STD', '../../fonts/circular-std/300-italic', 300, italic);
@include import-font-face('Circular STD', '../../fonts/circular-std/400', 400);
@include import-font-face('Circular STD', '../../fonts/circular-std/400-italic', 400, italic);
@include import-font-face('Circular STD', '../../fonts/circular-std/500', 500);
@include import-font-face('Circular STD', '../../fonts/circular-std/500-italic', 500, italic);
@include import-font-face('Circular STD', '../../fonts/circular-std/700', 700);
@include import-font-face('Circular STD', '../../fonts/circular-std/700-italic', 700, italic);
@include import-font-face('Circular STD', '../../fonts/circular-std/900', 900);
@include import-font-face('Circular STD', '../../fonts/circular-std/900-italic', 900, italic);

// Poppins
@include import-font-face('Poppins', '../../fonts/poppins/100', 100);
@include import-font-face('Poppins', '../../fonts/poppins/100-italic', 100, italic);
@include import-font-face('Poppins', '../../fonts/poppins/200', 200);
@include import-font-face('Poppins', '../../fonts/poppins/200-italic', 200, italic);
@include import-font-face('Poppins', '../../fonts/poppins/300', 300);
@include import-font-face('Poppins', '../../fonts/poppins/300-italic', 300, italic);
@include import-font-face('Poppins', '../../fonts/poppins/400', 400);
@include import-font-face('Poppins', '../../fonts/poppins/400-italic', 400, italic);
@include import-font-face('Poppins', '../../fonts/poppins/500', 500);
@include import-font-face('Poppins', '../../fonts/poppins/500-italic', 500, italic);
@include import-font-face('Poppins', '../../fonts/poppins/600', 600);
@include import-font-face('Poppins', '../../fonts/poppins/600-italic', 600, italic);
@include import-font-face('Poppins', '../../fonts/poppins/700', 700);
@include import-font-face('Poppins', '../../fonts/poppins/700-italic', 700, italic);
@include import-font-face('Poppins', '../../fonts/poppins/800', 800);
@include import-font-face('Poppins', '../../fonts/poppins/800-italic', 800, italic);
@include import-font-face('Poppins', '../../fonts/poppins/900', 900);
@include import-font-face('Poppins', '../../fonts/poppins/900-italic', 900, italic);
