.responsive-embed {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        padding-bottom: calculate-responsive-ratio(16, 9);
    }

    &.is-16by9,
    &.ratio-16-9 {
        &::before {
            padding-bottom: calculate-responsive-ratio(16, 9);
        }
    }

    &.is-4by3,
    &.ratio-4-3 {
        &::before {
            padding-bottom: calculate-responsive-ratio(4, 3);
        }
    }

    &.is-3by1,
    &.ratio-3-1 {
        &::before {
            padding-bottom: calculate-responsive-ratio(3, 1);
        }
    }

    &.is-1by1,
    &.ratio-1-1 {
        &::before {
            padding-bottom: calculate-responsive-ratio(1, 1);
        }
    }
}

.responsive-embed-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
