.certifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 2rem;
    @include media(get-bp(tablet-landscape)) {
        padding: 8rem 2rem;
    }
}

.certifications__paragraph {
    font-size: 1.8rem;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    max-width: 99rem;
    text-align: center;
    @include media(get-bp(tablet-landscape)) {
        font-size: 2.5rem;
        font-weight: 300;
    }
    .certifications & {
        margin-bottom: 4rem;
    }
}

.certifications__list {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0;
    list-style: none;
    @include media(get-bp(tablet-portrait)) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media(get-bp(tablet-landscape)) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include media(get-bp(desktop)) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.certifications__list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: get-color(light);
    padding: 3rem 2rem;
    border: 0.1rem solid get-color(secondary);
    img {
        max-width: 20rem;
    }
}
