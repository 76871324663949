.our-results {
    padding: 6rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media(get-bp(desktop)) {
        padding: 8rem 0;
    }
    .section-title {
        padding: 0 2rem;
        margin-bottom: 3rem;
        @include media(get-bp(desktop)) {
            margin-bottom: 6rem;
        }
    }
}

.our-results__results-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    padding: 0;
    list-style: none;
    @include media(get-bp(tablet-portrait)) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.our-result__results-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-bottom: 0.2rem solid darken(get-color(light), 8%);
    border-right: 0.1rem solid darken(get-color(light), 8%);
    border-left: 0.1rem solid darken(get-color(light), 8%);
    &:nth-last-child(-n+4) {
        border-bottom: 0;
    }
}

.our-results__result-title {
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 0;
    font-weight: 900;
    text-transform: uppercase;
    color: get-color(primary);
    @include media(get-bp(desktop)) {
        font-size: 6.5rem;
        line-height: 6.5rem;
    }
}

.our-results__result-label {
    font-style: normal;
    font-size: 2rem;
    font-weight: 100;
    color: get-color(primary);
    opacity: 0.6;
    @include media(get-bp(desktop)) {
        font-size: 4rem;
    }
}

