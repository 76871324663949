.matrizes {
    padding: 4rem 2rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: get-color(light);
}

.matrizes-paragraph {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    max-width: 100rem;
}
