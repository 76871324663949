.working-with-us__content {
    display: flex;
    padding: 5rem 2rem 8rem;
    justify-content: center;
    .form__input-icon {
        br {
            display: flex;
        }
    }
    .wpcf7-not-valid-tip {
        font-size: 1rem;
        position: absolute;
        bottom: -1.2rem;
        left: 1rem;
    }
    .form {
        width: 100%;
        max-width: 98.4rem;
        border-radius: 0.5rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 3rem;
        background: get-color(light);
        @include media(get-bp(tablet-landscape)) {
            padding: 4rem 3rem 6rem;
        }
    }
    .wpcf7-textarea {
        width: 100%;
    }
    .form__submit-button {
        width: 100%;
        @include media(get-bp(tablet-landscape)) {
            max-width: 15rem;
            margin-left: auto;
            display: flex;
            justify-content: center;
        }
        br { display: none; }
    }
    .wpcf7-form-control-wrap {
        position: relative;
    }
    .wpcf7-form-control-wrap[data-name="vaga"] {
        width: 100%;
        .wpcf7-select .form__select {
            width: 100%;
        }
    }
    .wpcf7-form-control-wrap[data-name="curriculo"] {
        input {
            background: transparent;
            box-shadow: none;
            max-width: 13rem;
        }
    }
}
