.social-network-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: get-color(social-network-footer);
    padding: 3rem 2rem;
}

.social-network-footer__title {
   font-size: 3rem;
   line-height: 3.5rem;
   text-align: center;
   margin-bottom: 1rem;
}

.social-network-footer__list {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
}

.social-network-footer__rede {
    color: get-color(primary);
    margin: 0 1rem;
    font-size: 4rem;
    transition: 0.3s;
    &:hover {
        transform: scale(1.1);
    }
    @include media(get-bp(tablet-portrait)) {
        font-size: 6rem;
    }
}
