.main-menu {
    padding: 0 4rem;
    margin-left: auto;
    @include media(get-bp(desktop), max) {
        display: none;
    }
    @include media(get-bp(desktop)) {
        display: flex;
    }
    .main-menu__list {
        display: flex;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: bold;
    }
    .main-menu__item,
    .menu-item {
        margin-right: 1.5rem;
        transition: 0.2s;
        font-size: 1.7rem;
        color: get-color(primary);
        opacity: 0.6;
        &:last-of-type {
            margin-right: 0;
        }
        &.is-active,
        &:hover {
            color: get-color(primary);
            opacity: 1;
        }
        @include media(get-bp(desktop-big)) {
            font-size: 2rem;
        }
    }
}

.main-menu__link {
    position: relative;
    &:hover {
        &::after {
            opacity: 1;
        }
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        background: get-color(primary);
        border-radius: 10rem;
        left: 50%;
        opacity: 0;
        transition: 0.2s;
        .is-active & {
            opacity: 1;
        }
    }
}
