.exia-component + .exia-component {
    margin-top: 6rem;
    padding-top: 6rem;
    border-top: 1px solid #ddd;
}

.exia-title {
    margin-bottom: 3rem;
    margin-top: 4rem;
    line-height: 1;
    font-weight: normal;
}
