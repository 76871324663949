html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: sans-serif;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    color: get-color(text);
    font-family: $font-family-body;
    font-size: $font-size-body;
    font-weight: normal;
    line-height: $line-height-body;
    overflow-x: hidden;
    background: #F4F4F4;
}

img {
    max-width: 100%;
}

b,
strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

a {
    color: inherit;
    text-decoration: none;
}

p,
address,
em {
    font-family: $font-family-paragraph;
}

pre {
    padding: 2rem 1.5rem;
    background-color: #f8f8f8;
    border: 0.1rem solid #ddd;
    border-radius: 0.2rem;
    font-size: 1.4rem;
}

code {
    background-color: rgba(#000, 0.05);
    display: inline-block;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border: 0.1rem solid rgba(#000, 0.05);
    border-radius: 0.3rem;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }
}
