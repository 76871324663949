.footer-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 2rem;
    background: get-color(light);
}

.footer-menu__logo {
    max-width: 100%;
    display: block;
    margin-bottom: 3rem;
    @include media(get-bp(desktop)) {
        margin-bottom: 5rem;
    }
}

.footer-menu__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    @include media(get-bp(desktop)) {
        flex-direction: row;
    }
}

.footer-memu__menu-item {
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    transition: 0.3s;
    &:hover {
        color: get-color(primary);
        transform: scale(1.1);
    }
    &:last-child {
        margin-bottom: 0;
    }
    @include media(get-bp(desktop)) {
        margin: 0 1rem;
    }
}
