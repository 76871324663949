.footer-copyright {
    background: get-color(footer-copyright);
    border-top: 0.2rem solid #F4F4F4;
    padding: 2rem 5.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .em {
        font-size: 1.8rem;
        font-weight: 500;
        font-style: normal;
    }
}

.footer-copyright__links {
    font-size: 1.6rem;
    font-weight: 400;

    a:hover {
        text-decoration: underline;
        color: get-color(primary);
    }
}
