.container__single {
    padding-top: 3rem;
    padding-bottom: 4rem;
    max-width: 120rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
    background: #fff;
}

.single-content-post_thumbnail-figure {
    display: flex;
    width: 100%;
    height: 40rem;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }
}
