.about-page-content {
    padding: 3rem 2rem;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 143.5rem;
    width: 100%;
    @include media(get-bp(tablet-landscape)) {
        padding: 8rem 2rem 13rem 2rem;
    }
    p {
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
    }
    figure {
        margin: 4rem 0;
    }
}

