.menu-mobile-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z(menu-mobile);
    background-color: rgba(0,0,0,0.39);
    transition: 0.3s;
    transform: translate3d(-100%, 0, 0);
    &.menu-mobile-containe--openned {
        transform: translate3d(0, 0, 0);
    }
    @media (min-width: get-bp(desktop)) {
        display: none !important;
    }
}

.menu-mobile {
    background: darken(get-color(primary), 10%);
    margin: 0;
    width: 25rem;
    z-index: 70;
    position: relative;
    height: 100%;
    padding: 0;
}

.menu-mobile__item {
    transition: 0.5s;
    &:nth-child(even) {
        background-color: darken(get-color(primary), 12%);
    }
    &:hover {
        background-color: darken(get-color(primary), 15%);
    }
}

.menu-mobile__item-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 2rem;
    color: get-color(light);
    .fa-chevron-down {
        transition: 0.5s;
        .menu-mobile__item--show-submenu & {
            transform: rotate(180deg);
        }
    }
}

.menu-mobile-submenu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 0;
    transition: 0.5s;
    .menu-mobile__item--show-submenu & {
        height: auto;
    }
}

.menu-mobile-submenu__item {
    border-bottom: 0.15rem solid rgba(255, 255, 255, 0.16);
    &:last-child {
        border-bottom: none;
    }
}

.menu-mobile-submenu__link {
    padding: 1rem 2rem;
    display: flex;
    background-color: darken(get-color(primary), 17%);
    color: get-color(light);
    font-size: 1.4rem;
}
