.essay {
    display: flex;
    flex-direction: column;
}

.essay__intro {
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media(get-bp(desktop)) {
        padding: 8rem 2rem;
    }
}

.essay__intro-paragraph {
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    max-width: 97.4rem;
    width: 100%;
    @include media(get-bp(tablet-portrait)) {
        font-size: 2.5rem;
        line-height: 2.8rem;
    }
}

.essay_list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    @include media(get-bp(desktop)) {
        flex-direction: row;
    }
}

.essay__list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 2rem;
    flex: 1;
    color: get-color(light);
    &:nth-child(odd) {
        background: lighten(get-color(primary), 5%);
    }
    &:nth-child(even) {
        background: darken(get-color(primary), 5%);
    }
    @include media(get-bp(tablet-portrait)) {
        padding: 6rem 3rem;
    }
    @include media(get-bp(desktop)) {
        padding: 10.3rem 6.3rem;
    }
    .button {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.4rem;
    }
}

.essay__list-item-title {
    text-align: center;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    max-width: 50.4rem;
    width: 100%;
    @include media(get-bp(tablet-portrait)) {
        font-size: 4.5rem;
        line-height: 4.8rem;
    }
}

.essay__list-item-paragraph {
    text-align: center;
    display: inline-block;
    max-width: 51.8rem;
    width: 100%;
    .essay__list-item & {
        margin-bottom: 2rem;
        @include media(get-bp(tablet-portrait)) {
            margin-bottom: 5.4rem;
        }
    }
}
