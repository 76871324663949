.inner-page-intro {
    background: get-color(primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: get-color(light);
    padding: 5rem 2rem;
}

.social-network-list-intro__title {
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 70rem;
    width: 100%;
    @include media(get-bp(tablet-landscape)) {
        margin-bottom: 3rem;
        font-size: 6rem;
        line-height: 6.5rem;
    }
}

.social-network-list-intro__summary {
    font-size: 1.8rem;
    font-weight: 300;
    display: inline-block;
    text-align: center;
    max-width: 70rem;
    width: 100%;
    .inner-page-intro & {
        margin-bottom: 2rem;
    }
}

.social-network-list-intro {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

.social-network-list-intro__item {
    margin: 0 0.5rem;
    font-size: 3rem;
    display: flex;
}

