.section-title {
    font-size: 3rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 900;
    color: get-color(primary);
    @include media(get-bp(tablet-portrait)) {
        line-height: 4rem;
        font-size: 4rem;
    }
    @include media(get-bp(desktop)) {
        line-height: 6rem;
        font-size: 6rem;
    }
}

.about-page-content {
    padding: 3rem 2rem;
    @include media(get-bp(desktop)) {
        padding: 3rem 2rem 6rem;
    }
    p {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}
