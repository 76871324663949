.care-coverage {
    display: flex;
    flex-direction: column;
    @include media(get-bp(desktop)) {
        flex-direction: row;
    }
}

.care-coverage__info {
    display: flex;
    flex-direction: column;
    background: get-color(light);
    @include media(get-bp(desktop)) {
        width: 30%;
    }
}

.care-coverage__info-cover {
    width: 100%;
    height: 25rem;
    position: relative;
    overflow: hidden;
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: z(above);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 0%, #000000 100%);
    }
    @include media(get-bp(tablet-portrait)) {
        height: 35.2rem;
    }
}

.care-coverage__info-cover__image {
    position: relative;
    z-index: z(default);
}

.care-coverage__info-header {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    border-bottom: 0.2rem solid darken(get-color(light), 10%);
    @include media(get-bp(tablet-portrait)) {
        padding: 5rem 3rem;
    }
}

.care-coverage__info-title {
    color: get-color(primary);
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    line-height: 3.5rem;
    font-size: 3rem;
    @include media(get-bp(tablet-portrait)) {
        font-size: 3.5rem;
    }
}

.care-coverage__address {
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: normal;
    font-weight: 500;
    @include media(get-bp(tablet-portrait)) {
        font-size: 2.8rem;
        line-height: 3rem;
    }
}

.care-coverage__contacts {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
}

.care-coverage__contacts-item {
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.care-coverage__contacts-person {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    h3 {
        color: get-color(primary);
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 0;
    }
    em {
        font-size: 1.8rem;
        font-weight: 200;
        font-style: normal;
    }
}

.care-coverage__contacts-info {
    font-size: 1.6rem;
    margin-bottom: 0;

    iframe {
        width: 100%;
    }
}

.care-coverage__map-image-container {
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(get-bp(desktop)) {
        width: 70%;
    }
}
