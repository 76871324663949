$z-layers: (
    "menu-mobile": 60,
    "modal-box": 52,
    "modal-bg": 51,
    "modal": 50,
    "menu": 40,
    "above": 10,
    "default": 0,
    "below": -10,
);

@function z($layer) {
    @if not map-has-key($z-layers, $layer) {
        @warn "Nenhuma camada chamada '#{$layer}' encontrada em no mapa $z-layers. Propriedade omitida.";
        @return null;
    }

    @return map-get($z-layers, $layer);
}
