.contact-us__content {
    padding: 3rem 2rem;
    @include media(get-bp(desktop)) {
        padding: 8rem 2rem 12rem;
    }
    ol, ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.contact-us__contacts-region {
    display: flex;
    flex-direction: column;
}

.contact-us__contacts-region__title {
    display: flex;
    padding: 2rem;
    align-items: center;
    background: get-color(light);
    width: 100%;
    max-width: 58rem;
    color: get-color(dark);
    margin-bottom: 0;
    @include media(get-bp(desktop)) {
        font-size: 4rem;
    }
    @include media(get-bp(desktop-big)) {
        max-width: 58.6rem;
    }
    i {
        margin-right: 1rem;
        color: get-color(primary);
        font-size: 3.5rem;
    }
}

.contact-us__contacts__list {
    display: grid;
    grid-template-columns: 1fr;
    @include media(get-bp(tablet-landscape)) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media(get-bp(desktop)) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media(get-bp(desktop-big)) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.contact-us__contacts__list-item {
    width: 100%;
    padding: 2rem;
    background: linear-gradient(
                    30deg, #f7f7f7 47.57%,
                    rgba(255, 255, 255, 0.79) 100%);
    @include media(get-bp(tablet-landscape)) {
        padding: 4rem;
    }
}

.contact-us__contacts__list-title {
    color: get-color(primary);
    font-size: 2rem;
    @include media(get-bp(desktop)) {
        font-size: 3rem;
    }
}

.contact-us__contacts__list-infos {
    display: flex;
    flex-direction: column;
    > b {
        font-size: 1.8rem;
        @include media(get-bp(desktop)) {
            font-size: 2.5rem;
        }
    }
    em {
        font-size: 1.4rem;
        font-style: normal;
        @include media(get-bp(desktop)) {
            font-size: 2rem;
        }
    }
}
