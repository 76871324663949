.matrizes-page-content {
    padding: 6rem 2rem;
    display: flex;
    justify-content: center;
    @include media(get-bp(tablet-landscape)) {
        padding: 6rem 2rem 14rem;
    }
    ul, ol {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.matrizes-regions {
    max-width: 70rem;
    width: 100%;
}

.matrizes-regions__region-address-wrap,
.matrizes-regions__region,
.matrizes-regions {
    display: flex;
    flex-direction: column;
    background-color: get-color(light);
}

.matrizes-regions__region-title {
    text-transform: uppercase;
    color: get-color(primary);
    word-break: break-word;
    margin-bottom: 0;
    line-height: 4rem;
    @include media(get-bp(tablet-landscape)) {
        font-size: 3.5rem;
    }
}

.matrizes-regions__region-address-wrap {
    padding: 3rem;
    border-bottom: 0.1rem solid darken(get-color(light), 10%);
}

.matrizes-regions__region-address {
    font-weight: 600;
    font-size: 2rem;
    font-style: normal;
}

.matrizes-regions__contacts {

}

.matrizes-regions__contacts__item {
    padding: 2rem;
    background: darken(get-color(light), 2%);
    border-bottom: 0.1rem solid darken(get-color(light), 10%);
}

.matrizes-regions__contacts-person {
    span {
        font-size: 1.6rem;
        display: block;
        font-weight: 400;
    }
}

.matrizes-regions__contacts-infos {
    display: block;
}
