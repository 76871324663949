.container {
    @include container($width: 179.8rem, $padding: 0 2rem);

    @include media(get-bp(desktop)) {
        padding: 0 2rem;
    }
}

.container--content {
    @include container($width: 100rem, $padding: 0);
}
