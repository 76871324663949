.burguer-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    position: relative;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 0.2rem;
    &.burguer-menu--active,
    &:hover {
        background: darken(get-color(light), 10%);
    }

    @media (min-width: get-bp(desktop)) {
        display: none !important;
    }
}

.burguer-menu__line {
    position: absolute;
    height: 0.3rem;
    width: 80%;
    background: get-color(dark);
    transition: 0.5s;
    &:nth-child(1) {
        top: 0.8rem;
        .burguer-menu--active & {
            top: 1.9rem;
            transform: rotate(45deg);
        }
    }
    &:nth-child(3) {
        bottom: 0.8rem;
        .burguer-menu--active & {
            top: 1.9rem;
            transform: rotate(130deg);
        }
    }
}

.burguer-menu__line--middle {
    .burguer-menu--active & {
        opacity: 0;
    }
}
