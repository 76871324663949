.form {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
}

.form__textarea,
.form__input {
    display: flex;
    border-radius: 0.5rem;
    color: get-color(primary);
    padding: 1.5rem 2rem;
    font-size: 1.8rem;
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &:last-child {
        margin-bottom: 0;
    }
    border: 0.2rem solid get-color(primary);
}

.form__input {
    margin-bottom: 2rem;
    align-items: center;
    input,
    select {
        padding: 0 1rem;
        width: 100%;
        border: none;
        &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    select {
        color: gray;
    }
    i {
        font-size: 2rem;
    }
}

.form__textarea {
    margin-bottom: 3rem;
    height: 25rem;
    color: get-color(dark);
}

.form__input-file {
    flex-direction: column;
    @include media(get-bp(tablet-portrait)) {
        flex-direction: row;
        justify-content: space-between;
    }
    label {
        color: gray;
        @include media(get-bp(tablet-portrait)) {
            margin-left: 2rem;
            margin-right: auto;
        }
    }
}

.form__submit-button {
    text-transform: uppercase;
    padding: 1.5rem 2rem;
    font-weight: bold;
    @include media(get-bp(tablet-portrait)) {
        margin-left: auto;
        padding: 1.5rem 3rem;
    }
}

.form__submit-button__icon {
    margin-right: 0.5rem;
}
