.main-header {
    background: get-color(light);
    padding: 1.4rem 0;
    color: get-color(dark);
    @include media(get-bp(desktop)) {
        padding: 2rem 0;
    }
    > .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .button {
        font-size: 1.6rem;
    }
}

.main-header__button-client-area {
    display: none;
    @include media(get-bp(desktop)) {
        display: flex;
    }
}

.main-header__logo-container {
    height: 5rem;
    margin: 0;

    @include media(get-bp(tablet-landscape)) {
        height: 7rem;
    }
}

.main-header__logo {
    display: inline-block;
    height: 100%;
}

.main-header__title {
    font-size: 0rem;
}
