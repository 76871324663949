.main-banner {
    height: 40rem;
    padding-bottom: 4rem;
    position: relative;
    @include media(get-bp(tablet-landscape)) {
        height: 60rem;
        padding-bottom: 0;
    }
    .swiper-pagination-bullet {
        transition: 0.3s;
        background: get-color(primary);
        opacity: 0.5;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.main-banner__slide {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
}

.main-banner__slide__overlay {
    display: block;
    position: absolute;
    background: rgb(22,157,98);
    background: linear-gradient(90deg, rgba(22,157,98,0.7931547619047619) 0%, rgba(22,157,98,0.8155637254901961) 43%, rgba(0,0,0,0) 100%);
    left: 0;
    right: 9;
    width: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.main-banner__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-banner__button-controls {
    color: get-color(primary);
    width: 4rem;
    height: 4rem;
    background: get-color(light);
    box-shadow: 0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    border-radius: 10rem;
    display: none;
    justify-content: center;
    align-items: center;
    top: 50%;
    position: absolute;
    z-index: z(above);
    margin-top: -2.5rem;
    cursor: pointer;
    transition: 0.3s;
    &.prev {
        left: 5%;
    }
    &.next {
        right: 5%;
    }
    @include media(get-bp(tablet-landscape)) {
        width: 6.5rem;
        height: 6.5rem;
        font-size: 3rem;
        display: flex;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.main-banner__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding: 3rem 2rem;
    z-index: z(above);
    color: get-color(light);
    @include media(get-bp(tablet-portrait)) {
        padding: 6rem 4rem;
    }
    @include media(get-bp(tablet-landscape)) {
        padding: 8rem 15rem;
    }
    @include media(get-bp(desktop)) {
        padding: 8rem 20rem;
    }
    @include media(get-bp(desktop-big)) {
        padding: 8rem 30rem;
    }
}

.main-banner__title {
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 3.5rem;
    max-width: 52rem;
    width: 100%;
    @include media(get-bp(tablet-landscape)) {
        font-size: 6rem;
        line-height: 6.2rem;
        margin-bottom: 1rem;
    }
}

.main-banner__resume {
    font-size: 1.4rem;
    display: inline-block;
    max-width: 48.7rem;
    width: 100%;
    @include media(get-bp(tablet-landscape)) {
        font-size: 2rem;
        font-weight: 400;
    }
    .main-banner__content & {
        margin-bottom: 1rem;
        @include media(get-bp(tablet-portrait)) {
            margin-bottom: 4rem;
        }
    }
}

.swiper-pagination {
    bottom: 0;
    @include media(get-bp(tablet-landscape)) {
        display: none;
    }
}

.main-banner__link {
    font-size: 1.4rem;
    padding-right: 2rem;
    padding-left: 2rem;
    text-transform: uppercase;
    width: fit-content;
    @include media(get-bp(tablet-landscape)) {
        font-size: 2rem;
    }
}
