.small-top-info-bar {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background-color: #F2F2F2;

    @media (min-width: get-bp(tablet-landscape)) {
        padding: 1rem 2rem;
        flex-direction: row;
        justify-content: space-between;
    }
}

.small-top-info-bar__text {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;

    @media (min-width: get-bp(tablet-landscape)) {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;
    }
}

.small-top-info-bar__icon {
    margin-right: 1rem;
    color: get-color(primary);
}

.small-top-info-bar__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.small-top-info-bar__info--middle {
    margin: 1rem 0;

    @media (min-width: get-bp(tablet-landscape)) {
        margin: 0 2rem;
    }
}

.menu-mobile-containe__student {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: lighten(get-color(primary), 5%);
    width: 25rem;
    .button:hover,
    .button {
        font-size: 1.6rem;
        background: get-color(light);
        color: get-color(primary);
    }
}

.menu-mobile-containe__student-fauser {
    margin-right: 0.5rem;
}
