.main-analyzes {
    display: flex;
    flex-direction: column;
    background: get-color(light);
    @include media(get-bp(desktop)) {
        flex-direction: row;
    }
}

.main-analyzes__intro-block {
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media(get-bp(desktop)) {
        width: 35%;
        padding: 15rem 6rem;
    }
    .section-title {
        @include media(get-bp(desktop)) {
            text-align: left;
        }
    }
}

.main-analyzes__intro-block-paragraph {
    font-size: 2rem;
    text-align: center;
    @include media(get-bp(desktop)) {
        text-align: left;
    }
}

.main-analyzes__analyzes {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media(get-bp(tablet-landscape)) {
        flex-direction: row;
    }
    @include media(get-bp(desktop)) {
        width: 65%;
    }
}

.main-analyzes__analyzes-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 3rem 2rem;
    box-shadow: -4px 0px 2px rgba(0, 0, 0, 0.1);
    flex: 1;
    @include media(get-bp(desktop)) {
        padding: 10rem 2rem;
    }
    .button {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2rem;
        padding: 2rem 4rem;
    }
}

.main-analyzes__analyzes-title {
    color: get-color(primary);
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 4rem;
    text-align: center;
    text-transform: uppercase;
    @include media(get-bp(desktop)) {
        font-size: 3rem;
    }
}

.main-analyzes__analyzes-paragraph {
    font-size: 1.8rem;
    text-align: center;
    display: inline-block;
    max-width: 30rem;
    width: 100%;
    @include media(get-bp(desktop)) {
        font-size: 2rem;
    }
    .main-analyzes__analyzes-item & {
        margin-bottom: 4rem;
    }
}
