.certifications-page-content {
    display: flex;
    justify-content: center;
    padding: 4rem 2rem;
    @include media(get-bp(tablet-landscape)) {
        padding: 6rem 2rem 14rem;
    }
    ul, ol {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.certifications-list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    max-width: 120rem;
    width: 100%;
}


.certifications-list__item-link {
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    @include media(get-bp(tablet-landscape)) {
        flex-direction: row;
        &:hover {
            border: 0.1rem solid get-color(primary);
            transform: scale(1.1);
        }
    }
}

.certifications-list__item-figure {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background: get-color(light);
    border-bottom: 0.1rem solid darken(get-color(light), 10%);
    margin-bottom: 0;
    @include media(get-bp(tablet-landscape)) {
        flex: 1;
    }
    img {
        max-width: 35rem;
        width: 100%;
    }
}

.certifications-list__item-content {
    padding: 3rem;
    background: darken(get-color(light), 2%);
    @include media(get-bp(tablet-landscape)) {
        flex: 3;
    }
}
